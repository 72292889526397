import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import "../Styles.css";
import EditProfessor from './EditProfessor';
import EditStudent from "./EditStudent";
import TitleHeader from '../util/TitleHeader';

// Edit page, showing different edit content depending
// on whether user is student or professor.
// State:
// user: object representing signed in user
//  undefined if not fetched yet
// status: user group name as string
//  undefined if not yet fetched
class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = { status: undefined, user: undefined };
  }

  async componentDidMount() {
    let userData = await this.getUser();

    this.setState({ user: userData });
    if (userData !== undefined) {
      const payload = userData.signInUserSession.idToken.payload;
      if (payload['cognito:groups'].includes("professors")){
        this.setState({ status: "professor" });
      }
      else if (payload['cognito:groups'].includes("students")){
        this.setState({ status: "student" });
      } else {
        window.location.href="join";
      }
    }
    else {
      window.location.href="join";
    }
  }

  getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      return userData;
    } catch (e) {
      return console.log('Not signed in');
    }
  }

  render() {
    let editPageContent = null;
    if (this.state.status === "professor") {
      editPageContent = <EditProfessor user={this.state.user} />;
    } else if (this.state.status === "student") {
      editPageContent = <EditStudent user={this.state.user} />;
    }

    return (
      <div>
        <TitleHeader user={this.state.user} />
        {editPageContent}
      </div>
    );
  }
}

export default Edit;