import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import SignUp from './signup/SignUp';
import Error from './Error';
import Edit from './edit/Edit';

class App extends Component {
  render() {
    return (
       <BrowserRouter>
        <div>
          <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/edit" component={Edit} exact/>
            <Route path="/join" component={SignUp} exact/>
            <Route component={Error}/>
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;