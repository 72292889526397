import { Component } from "react";
import "../Styles.css";

// StudentForm for SignUp page
// props: addStudent callback

class StudentForm extends Component {
  constructor(props) {
    super(props);
    const initialState = { firstName: '', lastName: '', pronouns: '' };
    this.state = initialState;
  }

  setInput = (key, value) => {
    this.setState({[key]: value});
  }

  submit = () => {
    this.props.addStudent(this.state);
  }

  render() {
    return <div>
            <label>
              First Name:
              <input
                onChange={event => this.setInput('firstName', event.target.value)}
                className="input"
                value={this.state.firstName}
              />
            </label>
            <label>
              Last Name:
              <input
                onChange={event => this.setInput('lastName', event.target.value)}
                className="input"
                value={this.state.lastName}
              />
            </label>
            <label>
              Pronouns:
              <input
                onChange={event => this.setInput('pronouns', event.target.value)}
                className="input"
                value={this.state.pronouns}
              />
            </label>
            <button onClick={this.submit}>Submit Profile</button>
          </div>;
  }
}

export default StudentForm;