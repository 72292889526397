/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      email
      firstName
      lastName
      pronouns
      professorUsernames
      requesters {
        items {
          id
          studentEmail
          professorEmail
          professorUsername
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      email
      firstName
      lastName
      pronouns
      professorUsernames
      requesters {
        items {
          id
          studentEmail
          professorEmail
          professorUsername
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      email
      firstName
      lastName
      pronouns
      professorUsernames
      requesters {
        items {
          id
          studentEmail
          professorEmail
          professorUsername
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRequester = /* GraphQL */ `
  mutation CreateRequester(
    $input: CreateRequesterInput!
    $condition: ModelRequesterConditionInput
  ) {
    createRequester(input: $input, condition: $condition) {
      id
      studentEmail
      professorEmail
      submission {
        question
        answer
      }
      letters {
        school
        instructions
        deadline
        institutionAlert
        status
      }
      professorUsername
      professor {
        email
        firstName
        lastName
        questions
        username
        requesters {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      student {
        email
        firstName
        lastName
        pronouns
        professorUsernames
        requesters {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRequester = /* GraphQL */ `
  mutation UpdateRequester(
    $input: UpdateRequesterInput!
    $condition: ModelRequesterConditionInput
  ) {
    updateRequester(input: $input, condition: $condition) {
      id
      studentEmail
      professorEmail
      submission {
        question
        answer
      }
      letters {
        school
        instructions
        deadline
        institutionAlert
        status
      }
      professorUsername
      professor {
        email
        firstName
        lastName
        questions
        username
        requesters {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      student {
        email
        firstName
        lastName
        pronouns
        professorUsernames
        requesters {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRequester = /* GraphQL */ `
  mutation DeleteRequester(
    $input: DeleteRequesterInput!
    $condition: ModelRequesterConditionInput
  ) {
    deleteRequester(input: $input, condition: $condition) {
      id
      studentEmail
      professorEmail
      submission {
        question
        answer
      }
      letters {
        school
        instructions
        deadline
        institutionAlert
        status
      }
      professorUsername
      professor {
        email
        firstName
        lastName
        questions
        username
        requesters {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      student {
        email
        firstName
        lastName
        pronouns
        professorUsernames
        requesters {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProfessor = /* GraphQL */ `
  mutation CreateProfessor(
    $input: CreateProfessorInput!
    $condition: ModelProfessorConditionInput
  ) {
    createProfessor(input: $input, condition: $condition) {
      email
      firstName
      lastName
      questions
      username
      requesters {
        items {
          id
          studentEmail
          professorEmail
          professorUsername
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfessor = /* GraphQL */ `
  mutation UpdateProfessor(
    $input: UpdateProfessorInput!
    $condition: ModelProfessorConditionInput
  ) {
    updateProfessor(input: $input, condition: $condition) {
      email
      firstName
      lastName
      questions
      username
      requesters {
        items {
          id
          studentEmail
          professorEmail
          professorUsername
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfessor = /* GraphQL */ `
  mutation DeleteProfessor(
    $input: DeleteProfessorInput!
    $condition: ModelProfessorConditionInput
  ) {
    deleteProfessor(input: $input, condition: $condition) {
      email
      firstName
      lastName
      questions
      username
      requesters {
        items {
          id
          studentEmail
          professorEmail
          professorUsername
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
