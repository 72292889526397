import { Component } from "react";
import SignInOutButton from "./SignInOutButton";

// A TitleHeader renders the RecDeck header
// Props: user: object representing the signed in user
class TitleHeader extends Component {
  render() {
    let userExists = this.props.user !== null && this.props.user !== undefined;
    let userDisplayName = "None";
    if (userExists) {
      userDisplayName = this.props.user.attributes.email;
    }

    return (
      <div className="top_bar">
        <p className="bar_item logo"><b>Rec</b>Deck</p>
        <div className="right">
          <p className="text">Signed in as: {userDisplayName}</p>
            <div className="right separated_top">
              <SignInOutButton isSignedIn={userExists} />
            </div>
        </div>
      </div>
    );
  }
}

export default TitleHeader;