import React, { Component } from 'react';
import { API, graphqlOperation} from 'aws-amplify';
import { getProfessor } from '../graphql/queries';
import { updateProfessor } from "../graphql/mutations";
import { Link } from "react-router-dom";
import SaveIndicator from '../util/SaveIndicator';
import '../Styles.css';

class EditProfessor extends Component {
  constructor(props) {
    super(props);
    const initialFormState = { firstName: '', lastName: '' };
    this.state = { saveStatus: null, professorFormState: initialFormState, questions: [''] };
  }

  async componentDidMount() {
    this.fetchProfessor(this.props.user.attributes.email);
  }

  fetchProfessor = async (userEmail) => {
    try {
      // Fetch professor data
      const profData = await API.graphql(graphqlOperation((getProfessor), {email: userEmail}));
      const profState = { firstName: profData.data.getProfessor.firstName,
                          lastName: profData.data.getProfessor.lastName }
      let profQuestions = profData.data.getProfessor.questions;
      this.setState({ questions: profQuestions, professorFormState: profState });
    } catch (err) { console.log('error fetching professor', err); }
  }

  updateProf = async () => {
    let hasQuestions = this.state.questions.length > 0;
    this.state.questions.forEach(element => {
      if (element === '' || element === undefined) hasQuestions = false;
    })
    if (!this.state.professorFormState.firstName ||
        !this.state.professorFormState.lastName ||
        !hasQuestions) {
      alert("All profile fields are required!");
      return;
    }
    this.setState({ saveStatus: false });
    try {
      const prof = this.state.professorFormState;
      let i = {...prof, email: this.props.user.attributes.email, questions: this.state.questions};
      await API.graphql(graphqlOperation(updateProfessor, {input: i}));
      this.setState({ saveStatus: true });
    } catch (err) {
      console.log('error updating professor:', err);
    }
  }

  setProfessorInput = (key, value) => {
    const newFormState = { ...this.state.professorFormState, [key]: value };
    this.setState({ professorFormState: newFormState, saveStatus: null });
  }

  // Functions for updating question inputs
  handleChange = (i, event) => {
    const values = [...this.state.questions];
    values[i] = event.target.value;
    this.setState({ questions: values, saveStatus: null });
  }

  handleAdd = () => {
    const values = [...this.state.questions];
    values.push( '' );
    this.setState({ questions: values, saveStatus: null });
  }

  handleRemove = (i) => {
    if (this.state.questions.length === 1) return;
    const values = [...this.state.questions];
    values.splice(i, 1);
    this.setState({ questions: values, saveStatus: null });
  }
  // End question input functions

  render() {
    return <div className="container">
            <p className="title">Profile</p>
            <SaveIndicator saveStatus={this.state.saveStatus} />
            <br/>
            <label>
              First Name:
              <input
                onChange={event => this.setProfessorInput('firstName', event.target.value)}
                className="input"
                value={this.state.professorFormState.firstName}
              />
            </label>
            <label>
              Last Name:
              <input
                onChange={event => this.setProfessorInput('lastName', event.target.value)}
                className="input"
                value={this.state.professorFormState.lastName}
              />
            </label>
            <br/>
            <label>
              Questions:
              { this.state.questions != null ? this.state.questions.map((field, idx) => {
                return (
                  <div key={idx}>
                    <input
                      type="text"
                      className="input"
                      value={field}
                      onChange={e => this.handleChange(idx, e)}
                    />
                    {<button type="button" onClick={() => this.handleRemove(idx)}>
                      x
                    </button>}
                  </div>
                )
              }) : null}
            </label>

            <button type="button" onClick={() => this.handleAdd()}>Add Question</button>
            <p></p>
            <button onClick={this.updateProf}>Save Changes</button>
            <Link to="/"><button type="button">Back to Students</button></Link>
          </div>;
  }
}

export default EditProfessor;