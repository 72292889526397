export function getDisplayStatus(rawStatus) {
  if (rawStatus === "NOTPROCESSED") return "Not Processed";
  else if (rawStatus === "DENIED") return "Denied";
  else if (rawStatus === "PENDINGCREATION") return "Pending Creation";
  else if (rawStatus === "PENDINGFINALIZATION") return "Pending Finalization";
  else if (rawStatus === "FINALIZED") return "Finalized";
  else if (rawStatus === "SUBMITTED") return "Submitted";
  else return rawStatus;
}

export function getStatusNum(rawStatus) {
  if (rawStatus === "NOTPROCESSED") return 0;
  else if (rawStatus === "PENDINGCREATION") return 1;
  else if (rawStatus === "PENDINGFINALIZATION") return 2;
  else if (rawStatus === "FINALIZED") return 3;
  else if (rawStatus === "SUBMITTED") return 4;
  else if (rawStatus === "DENIED") return 5;
  else return -1;
}