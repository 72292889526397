/* eslint-disable */

export const listStudents = /* GraphQL */ `
  query ListStudents(
    $email: String
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudents(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        firstName
        lastName
        pronouns
        professorUsernames
        requesters {
          nextToken
          items {
            studentEmail
            professorEmail
            professorUsername
            submission {
              question
              answer
            }
            letters {
              school
              instructions
              deadline
              status
            }
          }
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($email: String!) {
    getStudent(email: $email) {
      email
      firstName
      lastName
      pronouns
      professorUsernames
      requesters {
        items {
          id
          studentEmail
          professorEmail
          professorUsername
          professor {
            firstName
            lastName
          }
          submission {
            question
            answer
          }
          letters {
            school
            instructions
            deadline
            status
            institutionAlert
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getProfessor = /* GraphQL */ `
  query GetProfessor($email: String!) {
    getProfessor(email: $email) {
      email
      firstName
      lastName
      questions
      username
      requesters {
        items {
          id
          studentEmail
          professorEmail
          submission {
            question
            answer
          }
          letters {
            school
            instructions
            deadline
            status
            institutionAlert
          }
          student {
            email
            firstName
            lastName
            pronouns
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEnumValues = /* GraphQL */`
  query ListEnumValues($enum: String!) {
    enum: __type(name: $enum) {
      enumValues {
        name
      }
    }
  }
`;