import { Component } from "react";
import "../Styles.css";

// ProfessorForm for SignUp page
// props: addProfessor callback

class ProfessorForm extends Component {
  constructor(props) {
    super(props);
    const initialState = { firstName: '', lastName: '' };
    this.state = { input: initialState, questions: [''] };
  }

  setInput = (key, value) => {
    this.setState({ input: { ...this.state.input, [key]: value } });
  }

  addProfessor = () => {
    let hasQuestions = this.state.questions.length > 0;
    this.state.questions.forEach(element => {
      if (element === '' || element === undefined) hasQuestions = false;
    })
    if (!this.state.input.firstName || !this.state.input.lastName || !hasQuestions) {
      alert("All profile fields are required!");
      return;
    }
    this.props.addProfessor(this.state.input, this.state.questions);
  }

  submit = () => {
    this.props.addProfessor(this.state);
  }

  // Functions for updating/adding/removing question inputs
  handleChange = (i, event) => {
    const values = [...this.state.questions];
    values[i] = event.target.value;
    this.setState({ questions: values });
  }

  handleAdd = () => {
    const values = [...this.state.questions];
    values.push( '' );
    this.setState({ questions: values });
  }

  handleRemove = (i) => {
    if (this.state.questions.length === 1) return;
    const values = [...this.state.questions];
    values.splice(i, 1);
    this.setState({ questions: values });
  }
  // End question input functions

  render() {
    return <div>
            <label>
              First Name:
              <input
                onChange={event => this.setInput('firstName', event.target.value)}
                className="input"
                value={this.state.input.firstName}
              />
            </label>
            <label>
              Last Name:
              <input
                onChange={event => this.setInput('lastName', event.target.value)}
                className="input"
                value={this.state.input.lastName}
              />
            </label>
            <br/>
            <label>
              Questions:
              {this.state.questions.map((field, idx) => {
                return (
                  <div key={idx}>
                    <input
                      type="text"
                      className="input"
                      value={field}
                      onChange={e => this.handleChange(idx, e)}
                    />
                    <button type="button" onClick={() => this.handleRemove(idx)}>
                      x
                    </button>
                  </div>
                )
              })}
            </label>
            <button type="button" onClick={() => this.handleAdd()}>Add Question</button>
            <button onClick={this.addProfessor}>Submit Profile</button>
          </div>;
  }
}

export default ProfessorForm;