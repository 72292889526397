import { Component } from 'react';
import '../Styles.css';

// A SaveIndicator displays a status bar to the user indicating save status
// Props: saveStatus: can only be null/true/false
// saveStatus null: display nothing
// saveStatus true: display bar indicating changes are saved
// saveStatus false: display bar ongoing save status is incomplete
class SaveIndicator extends Component {
  constructor(props) {
    // Check isValidStatus invariant
    const isValidStatus = props.saveStatus === null || props.saveStatus === true
                          || props.saveStatus === false;
    console.assert(isValidStatus, props.saveStatus);
    super(props);
  }

  render() {
    const isSaved = this.props.saveStatus;

    let indicator = null;
    // Explicitly check for true and false, so if isSaved is null
    // nothing is displayed
    if (isSaved === true) {
      indicator = <div className="saved">Changes Saved</div>;
    } else if (isSaved === false) {
      indicator = <div className="updating">Updating...</div>;
    }

    return indicator;
  }
}

export default SaveIndicator;