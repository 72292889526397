import React, { Component } from 'react';
import { API, graphqlOperation} from 'aws-amplify';
import { getProfessor } from '../graphql/queries';
import { createRequester, updateStudent } from '../graphql/mutations';
import '../Styles.css';

class AddProfessor extends Component {

  constructor(props) {
    super(props);
    this.state = { input: '', profData: null, responses: null };
  }

  setResponse = (i, event) => {
    const values = [...this.state.responses];
    values[i] = {'question': values[i].question, 'answer': event.target.value};
    this.setState({ responses: values });
  }

  addProfessorRelation = async () => {
    const student = this.props.student;
    const profData = this.state.profData;
    const responses = this.state.responses;

    // Validate form
    let formFilled = true;
    responses.forEach(resp => {if(resp.answer === '') formFilled = false});
    if (!formFilled) {
      alert("All questions must be answered");
      return;
    }
    try {
      // Create requester model
      let inputData = {studentEmail: student.email, professorEmail: profData.email,
                       submission: responses, professorUsername: profData.username};
      const requester = await API.graphql(graphqlOperation((createRequester), {input: inputData}));
      // Add requester to knownProfs
      let knownProfData = this.props.knownProfs;
      let req = requester.data.createRequester;
      this.props.addProfInfo(req);
      this.props.setKnownProfs(knownProfData);
      // Update the student model with prof username for authorization
      let profUsers;
      if (student.professorUsernames === null) {
        profUsers = [profData.username];
      } else {
        profUsers = [...student.professorUsernames, profData.username];
      }
      let i = {email: student.email, professorUsernames: profUsers};
      const updatedStudent = await API.graphql(graphqlOperation((updateStudent), {input: i}));
      this.props.setStudent(updatedStudent.data.updateStudent);
      this.setState({ input: '', responses: null });
    }
    catch (err) { console.log("error adding professor", err) }
  }

  fetchProfessor = async () => {
    // Validate input
    if(this.state.input === '') {
      alert("Include an Email!");
      return;
    }
    if(this.alreadyAddedProfessor(this.state.input)) {
      alert("Professor has already been added!");
      return;
    }
    try {
      // Attempt to fetch professor
      const getProfData = await API.graphql(graphqlOperation((getProfessor), { email: this.state.input}));
      this.setState({ profData: getProfData.data.getProfessor });
      // Set questionarre
      const values = [];
      getProfData.data.getProfessor.questions.forEach(element => (
        values.push({'question': element, 'answer': ''})
      ));
      this.setState({ responses: values });
    }
    catch (err) {
      console.log("error getting professor", err);
      alert("Error Getting Professor! Professor with this email may not exist.");
    }
  }

  alreadyAddedProfessor = (email) => {
    console.assert(email != null);
    return this.props.student.requesters.items.map((item) => (
      email === item.professorEmail
    )).includes(true);
  }

  render() {
    let profForm = (
      <div>
        <p className="heading">Add a Professor</p>
        <label>
          Email:
          <input
            onChange={event => this.setState({ input: event.target.value })}
            className="input"
            value={this.state.input}
          />
        </label>
        <button onClick={this.fetchProfessor}>Confirm</button>
      </div>
    );
    let submissionForm = null;
    if (this.state.profData != null && this.state.responses != null) {
      submissionForm = (
        <div>
          <br/>
          <p className="text">
            To be able to request a letter from {this.state.profData.firstName}
            &nbsp;{this.state.profData.lastName} fill out these questions:
          </p>
          <br/>
          {this.state.responses.map((response, index) => (
            <div key={index}>
              <label>
                {response.question}:
                <input
                  type="text"
                  className="input"
                  value={response.answer}
                  onChange={e => this.setResponse(index, e)}
                />
              </label>
            </div>
          ))}
          <button onClick={this.addProfessorRelation}>Add Professor</button>
        </div>
      );
    }
    return (
      <React.Fragment>
        {
          this.props.student && this.props.student.firstName ? profForm : null
        }
        {
          this.state.profData ? submissionForm : null
        }
      </React.Fragment>
    );
  }
}

export default AddProfessor;