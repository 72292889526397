import React, { Component } from 'react';
import { Auth, API, graphqlOperation} from 'aws-amplify';
import { createStudent, createProfessor } from '../graphql/mutations';
import SignInOutButton from '../util/SignInOutButton';
import StudentForm from './StudentForm';
import ProfessorForm from './ProfessorForm';
import "react-datepicker/dist/react-datepicker.css";
import "../util/SignInOutButton";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null, isStudent: true, loading: false };
  }

  async componentDidMount() {
    let userData = await this.getUser();

    this.setState({ user: userData });
    if (userData != null) {
      if (userData.signInUserSession.idToken.payload['cognito:groups'].includes("professors") ||
          userData.signInUserSession.idToken.payload['cognito:groups'].includes("students")) {
        window.location.href="./";
      }
    }
  }

  getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      return userData;
    } catch (e) {
      console.log('Not signed in');
    }
  }

  addStudent = async (studentFormState) => {
    if (!studentFormState.firstName || !studentFormState.lastName || !studentFormState.pronouns) {
      alert("All student questions are required!");
      return;
    }
    try {
      const tempStudent = { ...studentFormState, email: this.state.user.attributes.email };
      await API.graphql(graphqlOperation(createStudent, {input: tempStudent}));
      this.addToGroup("students").then(() => {
        const currentSession = this.state.user.signInUserSession;
        this.state.user.refreshSession(currentSession.refreshToken, (err, session) => {
          window.location.href="./";
        })
      })
    } catch (err) {
      console.log('error creating student:', err);
    }
  }

  addProfessor = async (professorFormState, questions) => {
    try {
      const prof = { ...professorFormState, username: this.state.user.username, email: this.state.user.attributes.email, questions: questions};
      await API.graphql(graphqlOperation(createProfessor, {input: prof}));
      this.addToGroup("professors").then(() => {
        const currentSession = this.state.user.signInUserSession;
        this.state.user.refreshSession(currentSession.refreshToken, (err, session) => {
          window.location.href="./";
        })
      })
    } catch (err) {
      console.log('error creating professor:', err);
    }
  }

  // Assign user group
  addToGroup = async (group) => {
    this.setState({ loading: true });
    let apiName = 'AdminQueries';
    let path = '/addUserToGroup';
    let myInit = {
        body: {
          "username" : this.state.user.username,
          "groupname": group
        },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    };
    return await API.post(apiName, path, myInit);
  }

  render() {
    let loading = null;
    if (this.state.loading) {
      loading = <div className="updating">Creating Profile...</div>
    }
    return (
      <div>
        <div className="top_bar">
          <p className="bar_item logo"><b>Rec</b>Deck</p>
        </div>
        <div className="container">
          <p className="heading">Sign up for RecDeck</p>
          {
            this.state.user ? <p>Signing up with Google account: {this.state.user.attributes.email}</p> :
            <p>Sign up with a Google account</p>
          }
          { // sign in and sign out
            <SignInOutButton isSignedIn={this.state.user !== null && this.state.user !== undefined} />
          }
          {
            this.state.user != null ?
            <div>
              {loading}
              <p>Sign up as:</p>
              <div onChange={event => this.setState({ isStudent: (event.target.value === "student") })}>
                <input type="radio" value="student" name="type" defaultChecked/> Student
                <br/>
                <input type="radio" value="professor" name="type"/> Professor
              </div>
              <div className="gap-20"></div>
              {
                this.state.isStudent ?
                <StudentForm addStudent={this.addStudent}/> : <ProfessorForm addProfessor={this.addProfessor}/>
              }
            </div> : null
          }
        </div>
      </div>
    );
  }
}

export default SignUp;