import React, { Component } from 'react';
import { API, graphqlOperation} from 'aws-amplify';
import { getStudent } from '../graphql/queries';
import { updateStudent } from "../graphql/mutations";
import { Link } from "react-router-dom";
import SaveIndicator from '../util/SaveIndicator';
import '../Styles.css';

class EditStudent extends Component {
  constructor(props) {
    super(props);
    const initialFormState = { firstName: '', lastName: '', pronouns: '' };
    this.state = { saveStatus: null, studentFormState: initialFormState };
  }

  async componentDidMount() {
    this.fetchStudent(this.props.user.attributes.email);
  }

  fetchStudent = async (userEmail) => {
    try {
      // Fetch student data
      const studentData = await API.graphql(graphqlOperation((getStudent),
                                            {email: userEmail}));
      const stdntState = {  firstName: studentData.data.getStudent.firstName,
                            lastName: studentData.data.getStudent.lastName,
                            pronouns: studentData.data.getStudent.pronouns };
      this.setState({ studentFormState: stdntState });
    } catch (err) { console.log('error fetching student', err); }
  }

  saveStudent = async () => {
    const form = this.state.studentFormState;
    if (!form.firstName || !form.lastName) {
      alert("All profile fields are required!");
      return;
    }
    this.setState({ saveStatus: false });
    try {
      const prof = this.state.studentFormState;
      await API.graphql(graphqlOperation(updateStudent,
                        {input: {...prof, email: this.props.user.attributes.email}}));
      this.setState({ saveStatus: true });
    } catch (err) {
      console.log('error updating student:', err);
    }
  }

  setStudentInput = (key, value) => {
    const formState = ({ ...this.state.studentFormState, [key]: value });
    this.setState({ studentFormState: formState, saveStatus: null });
  }

  render() {
    return <div className="container">
          <p className="title">Profile</p>
          <SaveIndicator saveStatus={this.state.saveStatus} />
          <label>
            First Name:
            <br />
            <input
              onChange={event => this.setStudentInput('firstName',
                                                      event.target.value)}
              className="input"
              value={this.state.studentFormState.firstName}
            />
          </label>
          <br />
          <label>
            Last Name:
            <br />
            <input
              onChange={event => this.setStudentInput('lastName',
                                                      event.target.value)}
              className="input"
              value={this.state.studentFormState.lastName}
            />
          </label>
          <br />
          <label>
            Pronouns:
            <br />
            <input
              onChange={event => this.setStudentInput('pronouns',
                                                      event.target.value)}
              className="input"
              value={this.state.studentFormState.pronouns}
            />
          </label>
          <p></p>
          <button onClick={this.saveStudent}>Save Changes</button>
          <Link to="/"><button type="button">Back to Letters</button></Link>
        </div>
  }
}

export default EditStudent;