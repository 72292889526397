import React, { Component } from 'react';
import { API, graphqlOperation} from 'aws-amplify';
import { updateRequester } from '../graphql/mutations';
import { getProfessor, listEnumValues } from '../customgraphql/queries';
import { getStatusNum } from '../util/StatusUtil';
import SaveIndicator from '../util/SaveIndicator';
import { Link } from "react-router-dom";
import RequesterDisplay from "./RequesterDisplay";
import '../Styles.css';

class Professor extends Component {
  constructor(props) {
    super(props);
    this.state = { professor: null, letterStatuses: [], saveStatus: null };
  }

  componentDidMount() {
    this.fetchLetterStatuses();
    this.fetchProfessor(this.props.user.attributes.email);
  }

  fetchLetterStatuses = async () => {
    try {
      // Get letter status enum values
      const statusData = await API.graphql(graphqlOperation(listEnumValues, {enum: "LetterStatus"}));
      const statuses = statusData.data.enum.enumValues;
      this.setState({ letterStatuses: statuses });
    } catch (err) {
      console.log("error fetching letter statuses", err);
    }
  }

  fetchProfessor = async (userEmail) => {
    try {
      // Fetch professor data
      const profData = await API.graphql(graphqlOperation((getProfessor), {email: userEmail}));
      let rqs = profData.data.getProfessor.requesters.items;
      rqs.forEach(r => {
        if (r.letters !== null) {
          let index = 0;
          r.letters.forEach(l => {
            l.deadline = new Date(l.deadline);
            l.position = index; // Store initial position
            index++;
          })
        }
      });
      this.setState({ professor: profData.data.getProfessor});
    } catch (err) { console.log('error fetching professor', err) }
  }

  updateAlertSelection = async (reqIndex, letIndex, value) => {
    // React will not rerender unless the object reference changes
    let prof = this.getProfCopy();
    let tempRequester = prof.requesters.items[reqIndex];
    tempRequester.letters[letIndex].institutionAlert = value;
    this.setState({ professor: prof });
    this.updateReq(tempRequester);
  }

  updateStatusSelection = async (reqIndex, letIndex, value) => {
    // React will not rerender unless the object reference changes
    let prof = this.getProfCopy();
    let tempRequester = prof.requesters.items[reqIndex];
    tempRequester.letters[letIndex].status = value;
    this.setState({ professor: prof });
    this.updateReq(tempRequester);
  }

  // Update a requester associated with this professor
  updateReq = async (tempRequester) => {
    // Copy
    tempRequester = JSON.parse(JSON.stringify(tempRequester));
    tempRequester.letters.forEach(l => {
      l.deadline = new Date(l.deadline);
    });
    // Return to original sorted state and remove indexes
    this.returnOriginalSort(tempRequester.letters);
    tempRequester.letters.forEach(l => {
      delete l.position;
    });
    try {
      this.setState({ saveStatus: false });
      let i = {letters: tempRequester.letters, id: tempRequester.id};
      await API.graphql(graphqlOperation(updateRequester, {input: i}));
      this.setState({ saveStatus: true });
    }
    catch (err) {
      console.log("error updating status", err);
    }
  }

  getProfCopy = () => {
    let prof = JSON.parse(JSON.stringify(this.state.professor));
    prof.requesters.items.forEach(r => {
      if (r.letters !== null) {
        r.letters.forEach(l => {
          l.deadline = new Date(l.deadline);
        });
      }
    })
    return prof;
  }

  // Sorts all students by status
  sortByStatus = () => {
    let prof = this.getProfCopy();
    prof.requesters.items.forEach(r => {
      if (r.letters !== null) {
        this.sortLettersByStatus(r.letters);
      }
    });
    prof.requesters.items.sort((a, b) => (this.compareStatuses(a, b)));
    this.setState({ professor: prof });
  }

  compareStatuses = (a, b) => {
    let aVal, bVal = 100;
    if (a.letters !== null) aVal = (getStatusNum(a.letters[0].status));
    if (b.letters !== null) bVal = (getStatusNum(b.letters[0].status));
    return aVal - bVal;
  }

  // Sorts all students by deadline
  sortByDate = () => {
    let prof = this.getProfCopy();
    prof.requesters.items.forEach(r => {
      if (r.letters !== null) {
        this.sortLettersByDate(r.letters);
      }
    });
    prof.requesters.items.sort((a, b) => {
      let aVal, bVal = 8640000000000000;
      if (a.letters !== null) aVal = a.letters[0].deadline;
      if (b.letters !== null) bVal = b.letters[0].deadline;
      if (aVal < new Date()) aVal = 864000000000000;
      if (bVal < new Date()) bVal = 864000000000000;
      return aVal - bVal;
    });
    this.setState({ professor: prof });
  }

  // Functions for sorting one students letters
  sortLettersByDate = (letters) => {
    letters.sort((a, b) => (a.deadline - b.deadline));
  }

  sortLettersByStatus = (letters) => {
    letters.sort((a, b) => (getStatusNum(a.status) - getStatusNum(b.status)));
  }

  returnOriginalSort = (letters) => {
    letters.sort((a, b) => (a.position > b.position) ? 1 : -1);
  }
  // End

  render() {
    const professor = this.state.professor;
    return (
      <div className="container">
        { // Professor display
          professor ?
          <div>
          <Link to="/edit">
            <button type="button" className="right">Edit Profile</button>
          </Link>
          <p className="title">Welcome {professor.firstName} {professor.lastName}</p>
          </div> : null
        }
        { // Students/requesters display
        professor && professor.requesters.items.length > 0 ?
        <div>
        <SaveIndicator saveStatus={this.state.saveStatus} />
        <button onClick={this.sortByStatus} className="small">Sort By Status</button>
        <button onClick={this.sortByDate} className="small">Sort By Deadline</button>
        {professor.requesters.items.map((requester, reqIndex) => (
          <RequesterDisplay requester={requester} reqIndex={reqIndex} letterStatuses={this.state.letterStatuses}
            key={reqIndex} updateStatus={this.updateStatusSelection}  updateAlert={this.updateAlertSelection} />
        ))}</div>: "No students yet."}
      </div>
    );
  }
}

export default Professor;