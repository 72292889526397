import React, { Component } from 'react';
import { API, graphqlOperation} from 'aws-amplify';
import { getStudent} from '../customgraphql/queries';
import { updateRequester } from '../graphql/mutations';
import { Link } from 'react-router-dom';
import AddProfessor from './AddProfessor';
import { LetterDisplay } from './UpdateLetter';
import { ResponseDisplay} from './UpdateResponse';
import SaveIndicator from '../util/SaveIndicator';
import LetterForm from './LetterForm';
import '../Styles.css';

class Student extends Component {
  constructor(props) {
    super(props);
    this.state = { student: [], knownProfs: [], saveStatus: null };
  }

  componentDidMount() {
    this.fetchStudent(this.props.user.attributes.email);
  }

  addSingleProfInfo = (req) => {
    this.addProfInfo(this.state.knownProfs, req);
  }

  addProfInfo = (collection, req) => {
    collection.push({id: req.id, name: req.professor.firstName + " " +
                            req.professor.lastName, letters: req.letters,
                            submission: req.submission});
  }

  fetchStudent = async (userEmail) => {
    try {
      const studentData = await API.graphql(graphqlOperation((getStudent), { email: userEmail}));
      let student = studentData.data.getStudent;
      this.setState({ student: student });
      // store requesters of associated professors in knownProfData
      let knownProfData = [];
      student.requesters.items.forEach(req => {
        this.addProfInfo(knownProfData, req);
      });
      this.setState({ knownProfs: knownProfData });
    } catch (err) { console.log('error fetching student: ', err)}
  }

  addLetter = async (letterFormState, selectedProf) => {
    try {
      let lettersData;
      // Add required default data
      let newLetter = {...letterFormState, status: "NOTPROCESSED", institutionAlert: false};
      if (selectedProf.letters === null) {
        lettersData = [newLetter];
      } else {
        lettersData = [...selectedProf.letters, newLetter];
      }
      // Update the requester for this professor
      let i = {id: selectedProf.id, letters: lettersData};
      const letterData = await API.graphql(graphqlOperation((updateRequester), {input: i}));
      let profs = this.state.knownProfs;
      // Add updated requester to knownProfs
      let req = letterData.data.updateRequester;
      for (let i = 0; i < profs.length; i++) {
        let prof = profs[i];
        if (prof.id === req.id) {
          prof.letters = req.letters;
        }
      }
      this.setState({ knownProfs: [...profs] });
    }
    catch (err) { console.log("error submitting letter", err) }
  }

  updateLetter = async (letterData, index) => {
    let tempRequester = this.state.student.requesters.items[index];
    tempRequester.letters = letterData;
    this.updateReq(tempRequester);
  }

  updateReq = async (tempRequester) => {
    // Update a requester associated with this professor
    try {
      this.setState({ saveStatus: false });
      let i = {letters: tempRequester.letters, id: tempRequester.id,
                                   submission: tempRequester.submission};
      await API.graphql(graphqlOperation(updateRequester, {input: i}));
      this.setState({ saveStatus: true });
    }
    catch (err) { console.log("error updating status", err)}
  }

  render() {
    let letterForm = <LetterForm knownProfData={this.state.knownProfs} addLetter={this.addLetter} />
    let student = this.state.student;
    return (
      <div className="container">
        <div className="left_big">
        { // student display
          student && student.firstName ?
          <div>
            <Link to="/edit">
              <button type="button" className="right">Edit Profile</button>
            </Link>
            <p className="title">Welcome {student.firstName} {student.lastName}</p>

          </div> : null
        }
        <AddProfessor student={student} knownProfs={this.state.knownProfs} addProfInfo={this.addSingleProfInfo}
          setKnownProfs={(profs) => this.setState({ knownProfs: profs })} setStudent={(stdnt) => this.setState({ student: stdnt })}/>
        { // professors display
          this.state.knownProfs.length > 0 ?
          <div>
            <p className="title">Professors</p>
            <SaveIndicator saveStatus={this.state.saveStatus} />
            {this.state.knownProfs.map((prof, index) => (
              <div key={index} className="separated">
                <p className="heading">{prof.name}</p>
                <ResponseDisplay requester={prof} updateData={this.updateReq} clearSaveDisplay={() => this.setState({ saveStatus: null })} />
                { // letters display
                  prof.letters ?
                  <LetterDisplay letters={prof.letters} updateData={this.updateLetter}
                   reqIndex={index} clearSaveDisplay={() => this.setState({ saveStatus: null })} key={prof.letters} />
                  : <table><thead><tr><td>No letters yet.</td></tr></thead></table>
                }
              </div>
              ))}
          </div> : null
        }
        </div>
        <div className="right_small">
        { // letter form
          student && student.firstName ? letterForm : null
        }
        </div>
      </div>
    );
  }
}

export default Student;