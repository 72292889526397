import { Component } from 'react';
import DatePicker from 'react-datepicker'
import '../Styles.css';

const initialLetterState = { school: '', deadline: '', instructions: '' };
const initialSelectionState = {name: "No Professor Found", id: "none", letters: []};

class LetterForm extends Component {

  constructor(props) {
    super(props);
    this.state = { letter: initialLetterState, selection: initialSelectionState };
  }

  componentDidUpdate() {
    if (this.props.knownProfData.length !== 0 && this.state.selection === initialSelectionState) {
      this.setState({ selection: this.props.knownProfData[0] });
    }
  }

  setLetterInput = (key, value) => {
    this.setState({ letter: { ...this.state.letter, [key]: value } });
  }

  selectProf = (name) => {
    for (let i = 0; i < this.props.knownProfData.length; i++) {
      let prof = this.props.knownProfData[i];
      if(name === prof.name) {
        this.setState({ selection: prof });
      }
    }
  }

  addLetter = () => {
    // Validate form
    if(!this.state.letter.school || !this.state.letter.deadline) {
      alert("Institution and Deadline are required!");
      return;
    } else if (this.state.selection === initialSelectionState) {
      alert("Add a professor first!");
      return;
    }
    this.setState({ letter: initialLetterState });
    this.props.addLetter(this.state.letter, this.state.selection);
  }

  render() {
    if (this.state.selection !== initialSelectionState) {
      return (
        <div>
          <p className="heading">Request A Letter </p>
          <label>
            Institution:
            <br />
            <input
              onChange={event => this.setLetterInput('school', event.target.value)}
              className="input"
              value={this.state.letter.school}
            />
          </label>
          <br />
          <label>
            Deadline:
          </label>
          <br />
          {<DatePicker
            onChange={date => this.setLetterInput('deadline', date)}
            dateFormat="yyyy/MM/dd"
            selected={this.state.letter.deadline}
          />}
          <br />
          <label>
            Other Instructions:
            <br />
            <input
              onChange={event => this.setLetterInput('instructions', event.target.value)}
              className="input"
              value={this.state.letter.instructions}
            />
          </label>
          <br />
          <label>
            From:
            <br/>
            <select
              onChange={event => this.selectProf(event.target.value)}
              className="input"
              value={this.state.selection.name}>
                {this.props.knownProfData.map((prof, index) => (
                  <option key={index} value={prof.name}>
                    {prof.name}
                  </option>
                ))}
            </select>
          </label>
          <br />
          <button onClick={this.addLetter}>Submit Request</button>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default LetterForm;