import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import "./Styles.css";
import { Link } from "react-router-dom";
import TitleHeader from './util/TitleHeader';
import Professor from './professor/Professor';
import Student from './student/Student';

// The Home page displays the correct dashboard to users
// based on their user group.
// State: user: object representing currently signed in user
//  undefined if not fetched yet
// status: user group name as string
//  undefined if not yet fetched
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { user: undefined, status: undefined };
  }

  async componentDidMount() {
    let userData = await this.getUser();

    this.setState({ user: userData });
    if (userData != null) {
      if (userData.signInUserSession.idToken.payload['cognito:groups'].includes("professors")){
        this.setState({ status: "professor" });
      }
      else if (userData.signInUserSession.idToken.payload['cognito:groups'].includes("students")){
        this.setState({ status: "student" });
      }
      else {
        window.location.href="join";
      }
    }
  }

  getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      return userData;
    } catch (e) {
      console.log('Not signed in');
    }
  }

  render() {
    let home =
      <div className="container">
        <p className="title">Welcome to RecDeck</p>
        <p className="subtitle">The Letter of Recommendation Manager</p>
        <p className="text">Sign in or register with your Google account to get started.</p>
        <br />
        <Link to="/join">
          <button>Join RecDeck</button>
        </Link>
      </div>;

    let homePageContent = home;
    if (this.state.status === "professor") {
      homePageContent = <Professor user={this.state.user} />;
    } else if (this.state.status === "student") {
      homePageContent = <Student user={this.state.user} />;
    }

    return (
      <div>
        <TitleHeader user={this.state.user} />
        {homePageContent}
      </div>
    );
  }
}

export default Home;
