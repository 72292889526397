import React, { Component } from 'react';
import { getDisplayStatus } from '../util/StatusUtil';
import '../Styles.css';

class RequesterDisplay extends Component {
  render() {
    const requester = this.props.requester;
    const reqIndex = this.props.reqIndex;
    const updateStatus = this.props.updateStatus;
    const updateAlert = this.props.updateAlert;
    const letterStatuses= this.props.letterStatuses;
    return (
      <div key={reqIndex} className="separated">
        <span className="heading">{requester.student.firstName}&nbsp;{requester.student.lastName}</span>
        <span className="text indented">{requester.student.pronouns}</span>
        <table className="top_table">
        <thead>
          <tr><td colSpan={requester.submission.length * 2}>
            <div className= "table_title_div"><b>Submission</b></div>
          </td></tr>
        </thead>
        {
          <tbody><tr>
          {requester.submission.map((response, index) => (
            <React.Fragment key={index}>
              <td><b>{response.question}</b></td>
              <td>{response.answer}</td>
            </React.Fragment>
          ))}
          </tr></tbody>
        }</table>
        { // Letters display
          requester.letters ?
            <table style={{tableLayout: "fixed"}}>
              <thead>
              <tr>
                <td colSpan={5}>
                  <div className= "table_title_div"><b>Letters</b></div>
                </td>
              </tr>
              <tr>
                <td><b>School</b></td>
                <td><b>Deadline</b></td>
                <td><b>Instructions</b></td>
                <td><b>Email Received From Institution</b></td>
                <td><b>Status</b></td>
              </tr>
              </thead>
              <tbody>
              {requester.letters.map((letter, letIndex) => (
              <tr key={letIndex}>
                <td>{letter.school}</td>
                <td className={new Date() > letter.deadline ? "past" : null}>
                  {letter.deadline.toString().substring(0,15)}
                </td>
                <td>{letter.instructions ?
                letter.instructions : "No Instructions" }</td>
                <td className={"filled_table " + letter.institutionAlert}><select
                onChange={event => updateAlert(reqIndex, letIndex, event.target.value)}
                className={"table_select " + letter.institutionAlert}
                value={letter.institutionAlert}>
                  <option value={true}>
                    Yes
                  </option>
                  <option value={false}>
                    No
                  </option>
                </select></td>
                <td className={"filled_table " + letter.status}><select
                onChange={event => updateStatus(reqIndex, letIndex, event.target.value)}
                className={"table_select " + letter.status}
                value={letter.status}>
                  {letterStatuses.map((status, idx) => (
                    <option key={idx} value={status.name}>
                      {getDisplayStatus(status.name)}
                    </option>
                  ))}
                </select></td>
              </tr>
              ))}
              </tbody>
            </table>
          : <table><thead><td>No letters requested yet.</td></thead></table>
        }
      </div>
    );
  }
}

export default RequesterDisplay;