import { Component } from 'react';
 
class Error extends Component {
   render () {
      return (
         <div>
            <p>Error: Page does not exist!</p>
         </div>
      );
   }
}
 
export default Error;