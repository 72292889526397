import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

const signOutButton =
  <button onClick={() => Auth.signOut()}>Sign Out</button>;
const signInButton =
  <button onClick={
    () => Auth.federatedSignIn({provider: 'Google'})
  }>Sign In With Google</button>;

// A SignInOutButton renders button allowing the user to sign in or out
// Props: isSignedIn: Boolean/is user currently signed in
class SignInOutButton extends Component {
  constructor(props) {
    // Check isSignedIn invariant
    console.assert(typeof props.isSignedIn === 'boolean');
    super (props);
  }

  render() {
    if (this.props.isSignedIn) {
      return signOutButton;
    }
    else {
      return signInButton;
    }
  }
}

export default SignInOutButton;