import React, { Component } from 'react';
import { getDisplayStatus } from '../util/StatusUtil';
import DatePicker from 'react-datepicker';
import '../Styles.css';

// The letter display shows a student's letters associated with one prof
// Props: updateData: callback function to save edited letter
// State:
// letters: array of the student's letters from one prof
// isEditing: boolean is this set of letters being edited
class LetterDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { letters: LetterDisplay.convertDates(props.letters), isEditing: false };
  }

  static convertDates(letters) {
    letters.forEach(element => {
      element.deadline = new Date(element.deadline.toString());
    });
    return letters;
  }

  saveData = () => {
    if (this.state.isEditing) {
      // Pass letter data and requester index to student page to save data
      this.props.updateData(this.state.letters, this.props.reqIndex);
    } else {
      this.props.clearSaveDisplay();
    }
    this.setState( {isEditing: !this.state.isEditing} );
  }

  setLetterInput = (key, value, index) => {
    let l = JSON.parse(JSON.stringify(this.state.letters));
    l[index] = { ...this.state.letters[index], [key]: value };
    l = LetterDisplay.convertDates(l);
    this.setState({ letters: l });
  }

  removeLetter = (index) => {
    let l = JSON.parse(JSON.stringify(this.state.letters));
    l.splice(index, 1);
    l = LetterDisplay.convertDates(l);
    this.setState({ letters: l });
  }

  render() {
    let titles =
    <tr>
      <td><b>School</b></td>
      <td><b>Deadline</b></td>
      <td><b>Instructions</b></td>
      <td><b>Email Received From Institution</b></td>
      <td><b>Status</b></td>
      {this.state.isEditing ? <td></td> : null}
    </tr>;

    let display =
      <tbody>
        {titles}
        {this.state.letters.map((letter, letIndex) => (
          <tr key={letIndex}>
            <td>{letter.school}</td>
            <td>{letter.deadline.toString().substring(0,15)}</td>
            <td>{letter.instructions ?
              letter.instructions : "No Instructions" }
            </td>
            <td className={"" + letter.institutionAlert}>
              {letter.institutionAlert ? "Yes" : "No"}
            </td>
            <td className={letter.status}>{getDisplayStatus(letter.status)}</td>
          </tr>
        ))}
      </tbody>;

    let edit =
      <tbody>
        {titles}
        {this.state.letters.map((letter, letIndex) => (
          <tr key={letIndex}>
            <td className="filled_table">
              <input
                onChange={event => this.setLetterInput('school',
                                                        event.target.value,
                                                        letIndex)}
                className="input"
                value={letter.school}
              />
            </td>
            <td className="filled_table">
              {<DatePicker
                onChange={date => this.setLetterInput('deadline',
                                                      date,
                                                      letIndex)}
                dateFormat="yyyy/MM/dd"
                selected={letter.deadline}
              />}
            </td>
            <td className="filled_table">
              <input
                onChange={event => this.setLetterInput('instructions',
                                                        event.target.value,
                                                        letIndex)}
                className="input"
                value={letter.instructions}
              />
            </td>
            <td className={"" + letter.institutionAlert}>
              {letter.institutionAlert ? "Yes" : "No"}</td>
            <td className={letter.status}>
              {getDisplayStatus(letter.status)}
            </td>
            <td>
              <button onClick={() => this.removeLetter(letIndex)}>Delete</button>
            </td>
            </tr>
        ))}
      </tbody>;

    return  <table>
              <thead>
                <tr>
                  <td colSpan={6}>
                    <div className= "table_title_div">
                      <b>Letters</b>
                    </div>
                    <button className="table_button_r" onClick={this.saveData}>
                      {this.state.isEditing ? "Save": "Edit"}
                    </button>
                  </td>
                </tr>
              </thead>
              {this.state.isEditing ? edit : display}
            </table>;
  }
}

export { LetterDisplay };