import React, { Component } from 'react';
import '../Styles.css';

// The response display shows a student's responses associated with one prof
// Props: updateData: callback function to save edited letter
// State:
// req: the requester object associating this student and professor
// isEditing: boolean is this set of responses being edited
class ResponseDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { req: props.requester, isEditing: false };
  }

  saveData = () => {
    if (this.state.isEditing) {
      this.props.updateData(this.state.req);
    } else {
      this.props.clearSaveDisplay();
    }
    this.setState({ isEditing: !this.state.isEditing});
  }

  setResponse = (i, event) => {
    const values = [...this.state.req.submission];
    values[i] = { question: values[i].question, answer: event.target.value };
    this.setState({ req: {...this.state.req, submission: values} });
  }

  render() {
    let display =
    <tbody><tr>
    {this.state.req.submission.map((response, index) => (
      <React.Fragment key={index}>
        <td><b>{response.question}</b></td>
        <td>{response.answer}</td>
      </React.Fragment>
    ))}
    </tr></tbody>;

  let edit =
    <tbody><tr>
    {this.state.req.submission.map((response, index) => (
      <React.Fragment key={index}>
        <td>{response.question}</td>
        <td className="filled_table">
          <input
            className="input"
            value={response.answer}
            onChange={e => this.setResponse(index, e)}
          />
        </td>
      </React.Fragment>
    ))}
    </tr></tbody>;

    return <table className="top_table">
            <thead><tr>
              <td colSpan={this.state.req.submission.length * 2}>
                <div className= "table_title_div">
                  <b>Your Responses</b>
                </div>
              <button className="table_button_r" onClick={this.saveData}>
                {this.state.isEditing ? "Save": "Edit"}
              </button>
              </td>
            </tr></thead>{this.state.isEditing ? edit : display}
          </table>;
  }
}

export { ResponseDisplay };